div.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .logo-container {
    img {
      width: 250px;
      margin-bottom: 20px; } }

  .login-form {
    max-width: 500px;
    width: 400px; }
  .login-form-forgot {
    float: right; }
  .login-form-button {
    width: 100%; } }

