.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start!important;
  h4 {
    font-size: 20px; }
  h3 {
    margin-top: 41px;
    font-size: 24px; }
  .stats-container {
    margin-top: 24px;
    width: 1000px;
    .card-container {
      background-color: #e5e6ea;
      border-radius: 8px;
      padding: 24px;
      .portal-logo {
        max-height: 40px; }
      .portal-url-column {
        display: flex;
        justify-content: flex-end;
        flex: 1;
        align-items: center; }
      .sub-information {
        margin-top: 29px; } }
    .mini-stats-container {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      .ant-row {
        flex: 1!important;
        justify-content: space-between;
        .ant-col {
          flex: 1;
          margin-right: 10px!important; } }
      .stats-card {
        background-color: #e5e6ea;
        border-radius: 8px;
        width: 100%!important;
        display: flex;
        padding: 18px;
        max-height: 294px!important;
        height: 100%;
        flex: 1;
        overflow-y: hidden;

        &:hover {
          overflow-y: auto; }
        .number {
          width: 86px;
          background-color: #8b8989;
          height: 51px;
          border-radius: 4px;
          color: #fff;
          font-size: 24px;
          display: flex;
          justify-content: center;
          align-items: center; }
        .info {
          display: flex;
          flex: 1;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .title {
            font-size: 18px; }
          .subtitle {
            font-size: 13px; } }
        .ant-list {
          flex: 1;
          .connected-board-item {
            display: flex;
            flex: 1;
            justify-content: space-between;
            margin-top: 8px;
            a {
              text-decoration: underline; } } } } }
    .calendar-holder {
      display: flex;
      flex: 1;
      justify-content: center;
      margin-top: 30px;
      margin-bottom: 30px;
      .calendar-button {
        color: #8b8989;
        border: #8b8989 2px solid;
        font-weight: bold;
        padding: 10px;
        height: auto;
        border-radius: 10px; } }
    .stats-table-container {
      margin-top: 24px;
      border-radius: 8px;
      display: flex;
      flex-direction: column; }
    .stats-chart-container {
      background-color: #e5e6ea;
      margin-top: 24px;
      padding: 24px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      .header {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px; } } } }






