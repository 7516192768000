.site-layout-content {
  min-height: 500px;
  padding: 24px; }

section.layout {
  background-color: #fff;
  footer {
    display: flex;
    flex: 1;
    justify-content: center; } }
.main-header {


  .logo {
    float: left;
    width: 120px;
    height: 64px;
    margin: 0 24px 0px 0;

    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%; } } }

.main-content {
  display: flex;
  justify-content: center;
  min-height: 800px!important;
  .main-container {
    width: 960px!important;
    display: flex;
    margin-top: 44px; } }


.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px; }

